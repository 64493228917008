 import React from 'react'
 
 const Products = () => {
     return (
         <div>
             This is products page.
         </div>
     )
 }
 
 export default Products
 