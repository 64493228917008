
const About = ()=>{
return(
    <>
    <h1>About-Us</h1>
    <p> My name is Kushagra Upadhyaya,
        I am BTech CSE 3rd Yr student, and 
        I Work with Android Studio, React, and etc.
    </p>
    </>
);
}
export default About;